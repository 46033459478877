import React from 'react';
import { graphql } from 'gatsby';
import { connect } from "react-redux";
import get from 'lodash/get';
import Seo from '../components/seo';
import Header from '../components/Header';
import Footer from '../components/footer';
import Layout from '../components/layout';
import '../sass/main.scss';
import NotLastCategoryContent from '../components/BlockLibrary/Healthzone/CategoryContent/NotLastCategoryContent';
import LastCategoryContent from '../components/BlockLibrary/Healthzone/CategoryContent/LastCategoryContent';
import Breadcrumbs from '../components/BlockLibrary/Healthzone/Breadcrumbs'

class HzCategoryTemplate extends React.Component {
     constructor(props) {
          super(props);
          const category = get(this.props, 'data.contentfulHzCategory');

          this.state = {
               currentChildCategory: category.hz__category ? category.hz__category[0].categoryName : '',
               articlePerPage: 3,
               recentArticles: 3,
          }
     }

     componentDidMount() {
          const brower = typeof window !== `undefined`;

          if (brower) {
               var getOmValue = localStorage.getItem("Petinsurance_OM");
               this.props.setTrackingCode(getOmValue);
               var getPhoneValue = localStorage.getItem("Petinsurance_TrackingPhone");
               this.props.setTrackingPhone(getPhoneValue);
          }
          const trustpilotScript = document.createElement('script');
          trustpilotScript.type = 'text/javascript';
          trustpilotScript.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
          trustpilotScript.async = true
          document.head.appendChild(trustpilotScript)
          trustpilotScript.onload = function () {
               var trustbox = document.getElementsByClassName('trustpilot-widget');
               window.Trustpilot.loadFromElement(trustbox);
          };
     }

     render() {
          const category = get(this.props, 'data.contentfulHzCategory');
          const path = get(this.props, '*');
          const trackingCodeDefault = get(this.props, 'data.getTrackingCodeDefault');
          const trackingCode = category.trackingCode != null ? category.trackingCode : trackingCodeDefault.trackingCode;
          return (
               <Layout>
                    <Seo 
                         pageName={category.omniturePageNameLegacy || category.contentName}
                         specificPhone = {category.specificPhone}
                         trackingCode = {trackingCode}
                         title={category.seoTitleTag}
                         path={path}
                         canonical={category.seoCanonicalUrl ? category.seoCanonicalUrl.seoCanonicalUrl : ''}
                         description={category.seoMetaDescription ? category.seoMetaDescription.seoMetaDescription : ''}
                         robots={category.robotsDirective ? category.robotsDirective.robotsDirective : ''}
                         image={category.socialImage ? category.socialImage : category.featuredImage}
                         category={category}
                         hasBreadcrumb={true}
                         showAntiFlickerSnippet={category.showAntiFlickerSnippet}
                    />
                    <Header header={category.header}></Header>
                    <main id="main-content" role="main">
                         <Breadcrumbs category={category}></Breadcrumbs>
                         {
                              category.hz__category != null ? (
                                   <NotLastCategoryContent category={category} />
                              ) : (
                                        category.hz__article != null && (
                                             <LastCategoryContent
                                                  category={category}
                                                  allArticles={category.hz__article}
                                             />
                                        )
                                   )
                         }
                    </main>
                    <Footer footer={category.footer}></Footer>
               </Layout>
          )
     }
}

const mapDispatchToProps = dispatch => {
     return {
          setTrackingCode: (trackingCode) => dispatch({ type: `TRACKING_CODE`, payload: trackingCode }),
          setTrackingPhone: (trackingPhone) => dispatch({ type: `TRACKING_PHONE`, payload: trackingPhone }),
     };
}
const mapStateToProps = (state) => {
     return {
          state,
     };
}

export default connect(mapStateToProps, mapDispatchToProps)(HzCategoryTemplate);

export const pageQuery = graphql`
     query HzCategoryBySlug($slug: String!) {
          site {
               siteMetadata {
                    title
               }
          }
          contentfulHzCategory(slug: {eq: $slug }) {
               ...ContentfulHzCategoryFields
          }
          getTrackingCodeDefault : contentfulPage(contentful_id: {eq: "wChsWYdrCJ5doI8QdVkBk"}) {
               id
               trackingCode
          }
     }
`
